import React, { useEffect } from "react"
import { useCookie } from "../hooks/useCookie"
import { useState } from "react"

export const withABTesting = WrappedComponent => {
  return function ABTestingComponent(props) {
    const [getCookie, updateCookie] = useCookie("abTest", "")
    const [version, setVersion] = useState("")

    useEffect(() => {
      const existingVersion = getCookie
      if (!existingVersion) {
        // A/B testing logic to emit 'version-1' or 'version-2'
        const randomVersion = Math.random() < 0.5 ? "version-1" : "version-2"

        updateCookie(randomVersion, { maxAge: 6 * 60 * 60 })
      } else {
        // Use the existing version from the cookie
        setVersion(existingVersion)
      }
    }, [setVersion, getCookie, updateCookie])

    return <WrappedComponent abVersion={version} {...props} />
  }
}

// Example usage:
// const MyComponent = ({ abVersion }) => {
//   // Use abVersion in your component based on the A/B testing result
//   return <div>Current A/B Test Version: {abVersion}</div>;
// };

// const MyComponentWithABTesting = withABTesting(MyComponent);
