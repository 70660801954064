import { useState, useEffect } from "react"

export const useCookie = (cookieName, initialValue) => {
  const isBrowser = typeof window !== "undefined"

  const getCookie = () => {
    if (isBrowser) {
      const cookies = document.cookie.split("; ")
      const cookie = cookies.find(c => c.startsWith(`${cookieName}=`))
      return cookie ? cookie.split("=")[1] : initialValue
    }
    return initialValue
  }

  const [cookie, setCookie] = useState(getCookie())

  const updateCookie = (value, options = {}) => {
    if (isBrowser) {
      // Destructure options object
      const { maxAge, expires, path, domain, secure } = options

      // Create the initial cookie string with the provided value
      let cookieString = `${cookieName}=${value}`

      // Append optional attributes to the cookie string
      if (maxAge) cookieString += `; max-age=${maxAge}`
      if (expires) cookieString += `; expires=${expires.toUTCString()}`
      if (path) cookieString += `; path=${path}`
      if (domain) cookieString += `; domain=${domain}`
      if (secure) cookieString += `; secure`

      // Update the document's cookie with the new cookie string
      document.cookie = cookieString

      // Update the state with the new value
      setCookie(value)
    }
  }

  const deleteCookie = (options = {}) => {
    if (isBrowser) {
      // Set cookie to expire immediately to delete it
      updateCookie("", { expires: new Date(0), ...options })
    }
  }

  useEffect(() => {
    // This effect will run only on the client side
    setCookie(getCookie())
  }, [])

  return [cookie, updateCookie, deleteCookie]
}
