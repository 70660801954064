import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Helmet from "react-helmet"
import { withABTesting } from "../hoc/withABTesting"

export const query = graphql`
  query Resources {
    wpcontent {
      resources(first: 99) {
        nodes {
          id
          title
          uri
          excerpt
          featuredImage {
            node {
              sourceUrl
            }
          }
          ebookCoverImage
        }
      }
      seo {
        contentTypes {
          resource {
            metaDesc
            title
          }
        }
      }
    }
  }
`

const Resources = ({ data, abVersion }) => {
  const [loadedContent, setLoadedContent] = useState(7)
  const [showHidden, setShowHidden] = useState(false)
  const [renderJSX, setRenderJSX] = useState([])
  const resources = data.wpcontent.resources.nodes
  const resourceSeo = data.wpcontent.seo.contentTypes.resource
  const pageTitle = resourceSeo.title
  const metaDesc = resourceSeo.metaDesc

  const defaultJSX = () => {
    return (
      <div className="resource-listing mt-10 md:mt-5 grid grid-cols-1 sm:gap-3 md:gap-9 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 w-full lg:w-10/12 justify-center items-center">
        {resources.map((resource, index) => {
          return (
            <>
              <article
                key={resource.id}
                className="flex justify-center items-center"
              >
                <div className="relative z-1 bg-white p-5 card-1 mb-3 mt-3 md:mb-0 rounded-lg overflow-hidden shadow-lg transform transition duration-500 ease-in-out justify-center items-center">
                  <img
                    loading="lazy"
                    className="pb-0 rounded-sm"
                    height="239"
                    width="287"
                    src={resource.ebookCoverImage}
                    alt={resource.title}
                  />
                  <div className="bg-white py-6 pb-2 h-full">
                    <div
                      className="box-subtitle mb-2"
                      dangerouslySetInnerHTML={{
                        __html: resource.publishedDate,
                      }}
                    />
                    <h2
                      className="box-title font-bold mb-3 line-clamp-2"
                      dangerouslySetInnerHTML={{ __html: resource.title }}
                    />
                    <div
                      className="box-subtitle line-clamp-3 mb-3"
                      dangerouslySetInnerHTML={{ __html: resource.excerpt }}
                    />
                    <div className="learnmore-btn mb-0 text1">
                      {resource.uri ===
                      "/resources/free-ebook-rewards-recognition/" ? (
                        <Link
                          className="text-purple-500 inline-flex items-center"
                          to={
                            abVersion === "version-1"
                              ? `${resource.uri}`
                              : `/free-ebook-rewards-recognition-2/`
                          }
                        >
                          <span className="a-stroke w-4 bg-purple-500 mr-2 relative"></span>
                          <span className="text-purple-500 font-medium text-sm uppercase">
                            Read now
                          </span>
                          <svg
                            className="w-6 h-6 top-0"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                          >
                            <polygon
                              fill="currentColor"
                              points="15 12 9 16 9 8"
                            ></polygon>
                          </svg>
                        </Link>
                      ) : (
                        <Link
                          className="text-purple-500 inline-flex items-center"
                          to={`${resource.uri}`}
                        >
                          <span className="a-stroke w-4 bg-purple-500 mr-2 relative"></span>
                          <span className="text-purple-500 font-medium text-sm uppercase">
                            Read now
                          </span>
                          <svg
                            className="w-6 h-6 top-0"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                          >
                            <polygon
                              fill="currentColor"
                              points="15 12 9 16 9 8"
                            ></polygon>
                          </svg>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </article>
            </>
          )
        })}
      </div>
    )
  }

  return (
    <Layout>
      <Helmet>
        <title>{pageTitle}</title>

        <meta name="description" content={metaDesc} />

        <meta property="og:title" content={pageTitle} />

        <meta property="og:description" content={metaDesc} />

        <meta property="twitter:title" content={pageTitle} />

        <meta property="twitter:description" content={metaDesc} />

        <meta property="og:type" content="website" />

        <meta
          property="og:url"
          content="https://www.vantagecircle.com/resources/"
        />

        <meta property="og:site_name" content="Vantage Circle" />
        <link
          rel="canonical"
          href="https://www.vantagecircle.com/resources/"
        ></link>
      </Helmet>

      <section className="bg-purple-50  mx-auto py-10 pb-2 sm:py-20 sm:pb-0 md:py-40 relative">
        <div className="container max-w-7xl sm:text-center px-6">
          <h1 className="hero-title-2">
            <span className="block">Guides and Ebooks</span>
          </h1>
          <p className="section-subtitle">
            Ebooks, guides and best practices
            <br /> to help you transform your company culture.
          </p>
        </div>
        <img
          className="absolute z-1 hero-iconsl1 hero-icons-top"
          src="https://res.cloudinary.com/vantagecircle/image/upload/w_200/gatsbycms/uploads/2021/06/GuidesnEbooks-Hero-L1.png"
          alt="Guides and Ebooks"
        />
        <img
          className="absolute z-1 hero-iconsl2 hero-icons-bottom hero-icons-1"
          src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/gatsbycms/uploads/2021/06/GuidesnEbooks-Hero-L2.png"
          alt="Guides and Ebooks"
        />
        <img
          className="absolute z-1 hero-iconsr1 hero-icons-top"
          src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/06/GuidesnEbooks-Hero-R1.png"
          alt="Guides and Ebooks"
        />
        <img
          className="absolute z-1 hero-iconsr2 hero-icons-bottom hero-icons-1"
          src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/06/GuidesnEbooks-Hero-R2.png.png"
          alt="Guides and Ebooks"
        />
      </section>
      <div className="slant-bg bg-purple-50 slant-bg-purple py-2 sm:py-5 md:py-8 lg:py-10">
        <div className="">&nbsp;</div>
      </div>
      <section className="guides-list container px-6 lg:px-0 max-w-7xl mx-auto pt-5 sm:pt-10 md:pt-40 md:-mt-72 flex flex-col justify-center items-center pb-20">
        {defaultJSX()}
      </section>
    </Layout>
  )
}

export default withABTesting(Resources)
